import React from "react";

import Layout from "../components/Layout/Layout";
import Info from "../components/pages/AboutUs/Info/Info";
import OurTeam from "../components/pages/AboutUs/OurTeam/OurTeam";

const AboutUsPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Info />
      {/*<OurTeam />*/}
    </Layout>
  );
};

export default AboutUsPage;

import React from "react";

import styles from "./Offers.module.scss";
import { Link } from "react-router-dom";
import { IInclude } from "../Home/Popular/Desktop/PopularDesktop";
import Modal from "../../UI/Modal/Modal";

const includeService01 = [
  {
    id: 0,
    text: "регистрация ООО бесплатно, до подписания основного договора",
  },
  { id: 1, text: "отсрочка платежа до перехода проекта на 2-й этап в соответствии с условиями договора с ФСИ" },
  {
    id: 2,
    text: "оказываемые услуги не входят в лимиты 25% по заказу услуг / работ в соответствии с правилами Фонда",
  },
];
const setWhoWillSuit01 = [
  {
    id: 0,
    text: "Ближайшие мероприятия",
  },
];
const complexServicesInclude01 = [
  {
    id: 1,
    text: "услуга «Регистрация ООО» и услуга «Ликвидация / прекращение деятельности ООО» (прим. 1)",
  },
  {
    id: 2,
    text: "юридическое обслуживание по тарифу «Старт-up» (прим. 2); ",
  },
  {
    id: 3,
    text: "бухгалтерское обслуживание по тарифу «Оптимальный» (прим. 3); ",
  },
  {
    id: 4,
    text: "услуга «Постановка организации на учет» (прим. 4);  ",
  },
  {
    id: 5,
    text: "услуга «Аутсорсинг воинского учета» (прим. 5);  ",
  },
];
const servicePeriod01 = [
  {
    id: 1,
    text: "1 год + период ликвидации ООО;",
  },
];

const Offers: React.FC = () => {
  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "compare" | "feedback" | null>(null);

  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");
  const [whoWillSuitDescription, setWhoWillSuitDescription] = React.useState<IInclude[]>(setWhoWillSuit01);
  const [complexServices, setComplexServices] = React.useState<IInclude[]>(complexServicesInclude01);
  const [servicePeriod, setServicePeriod] = React.useState<IInclude[]>(servicePeriod01);

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    //include: IInclude[],
    perMounth: boolean,
    individualPrice: string,
    //whoWillSuitDescription: IInclude[],
    //complexServices: IInclude[],
    //servicePeriod: IInclude[]
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
    setWhoWillSuitDescription(whoWillSuitDescription);
    setComplexServices(complexServices);
    setServicePeriod(servicePeriod);
  };

  return (
    <div className={styles.offers}>
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          //includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
          isRate={false}
          //whoWillSuitDescription={whoWillSuitDescription}
          promotion={false}
          //complexServices={complexServices}
          //servicePeriod={servicePeriod}
        />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={`${styles.label} title`}>Акционные предложения</h2>
          <div className={styles.body}>
            <div className={styles.column}>
              <div className={`${styles.item} ${styles.members}`} tabIndex={1}>
                <div className={styles.image}>
                  <img src="/images/main/services/offers04.jpg" alt="" />
                </div>
                <div className={styles.info}>
                  <h2>Ближайшие мероприятия</h2>
                  <p>{/*Только первые 100 победителей получат скидку 100 тысяч на комплексное обслуживание!*/}</p>
                  <Link
                    to="/offers"
                    className={styles.more}
                    onClick={() => {
                      handleOpenModal(
                        "Ближайшие мероприятия",
                        "",
                        0,
                        `«21.02.2025 – онлайн встреча «Правовые вопросы регистрации ООО»`,
                        //includeService01,
                        false,
                        "",
                        //setWhoWillSuit01,
                        //complexServicesInclude01,
                        //servicePeriod01
                      );
                      setModalMode("more");
                      setIsModalActive(true);
                    }}
                  >
                    Подробнее
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;

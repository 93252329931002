import { warn } from "console";
import React from "react";

import { ChangeEvent, FormEvent, useState, useEffect } from "react";

import styles from "./CalculatorSalary.module.scss";

const CalculatorSalary: React.FC = () => {

    interface TypeSalaryValueRequest {
        [key: string]: string;
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isCheckedName, setIsCheckedName] = useState("");

    const [dataSalary, setDataSalary] = useState<TypeSalaryValueRequest>({
        otDateChill: "0",
        doDateChill: "0",
        otDateWork: "0",
        doDateWork: "0",
        salary: "",
        typeChill: "null",
        rateFSS: "",
        statusMCP: "no",

    });

    console.log("\r\n\r\n");
    console.log("%c████████╗███████  ██████╗ ████████ ████████╗ ███████ ████████╗ ██████╗\r\n██╔════╝ ██╔═══█  ██╔═══╝ ██╔════╝ ╚══██╔══╝ ██╔══██╗╚══██╔══╝██╔═══██╗\r\n█████╗   ███████  █████╗  █████╗      ██║    ███████║   ██║   ██║   ██║\r\n██╔══╝   ████══╝  ██╔══╝  ██╔══╝      ██║    ██╔══██║   ██║   ██║   ██║\r\n██║      ██║ ██╗  ██████╗ ███████╗    ██║    ██║  ██║   ██║   ╚██████╔╝\r\n╚═╝      ╚═╝ ╚═╝ ╚══════╝ ╚══════╝    ╚═╝    ╚═╝  ╚═╝   ╚═╝    ╚═════╝", "color: blue; font-size: 12px;");
    console.log("   🌵         🌵      🌵                🌵             🌵      🦖  \r\n🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩🟩");
    console.log("\r\n\r\n");

    // TTTF - TwoThousandTwentyFifth (2025 год)

    const mrot = 33660; // TODO Менять при каждом изменении МРОТ

    const holidaysTTTF = [ // TODO Менять каждый год (данные обновляются)
        "2025-01-01", "2025-01-02", "2025-01-03", "2025-01-06", "2025-01-07", "2025-01-08", // Январские праздники, Январь
        "2025-02-23", // День защитника отечества, Февраль
        "2025-03-08", // Международный женский день, Март
        "2025-05-01", "2025-05-02", "2025-05-08", "2025-05-09", // День труда, День победы, Май
        "2025-06-12", "2025-06-13", // День России, Июнь
        "2025-11-03", "2025-11-04", // День народного единства, Ноябрь
        "2025-12-31", "2025-11-04", // Новый год, Декабрь
    ].map(date => new Date(date).toDateString());

    const countWorkDaysTTTF = [ // TODO Менять каждый год (данные обновляются)
        17, // Январь
        20, // Февраль
        21, // Март
        22, // Апрель
        18, // Май
        19, // Июнь
        23, // Июль
        21, // Август
        22, // Сентябрь
        23, // Октябрь
        19, // Ноябрь
        22, // Декабрь
    ];

    // слушатель для адаптива

    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
    });

    // форматирование даны в дни (сортировка чиловых и рабочих дней)

    async function formatedDate(startDateWork: string, endDateWork: string, startDateChill: string, endDateChill: string) {
        const startWork = new Date(startDateWork);
        const endWork = new Date(endDateWork);

        const startChill = new Date(startDateChill);
        const endChill = new Date(endDateChill);

        var chillDays = [{ // вычленение чиловых дней
            month: 0,
            day: 0,
        }];

        for (let d = new Date(startChill); d <= endChill; d.setDate(d.getDate() + 1)) {
            const dayOfWeek = d.getDay();
            const correntDay = d.getDate();
            const correntMon = d.getMonth();
            const dateStr = d.toDateString();

            if (dayOfWeek !== 0 && dayOfWeek !== 6 && !holidaysTTTF.includes(dateStr)) {
                chillDays.push({
                    month: correntMon,
                    day: correntDay,
                });
            }
        };

        chillDays.shift();

        //------------------------------------------------- FREETATO

        var counterDaysWork = 0;
        var counterDaysChill = 0;
        var saveMonth = startWork.getMonth();

        var workDays = [{ // подсчет количества рабочих дней
            month: 0,
            countDayWork: 0,
            countDayChill: 0,
        }];

        for (let d = new Date(startWork); d <= endWork; d.setDate(d.getDate() + 1)) {
            const dayOfWeek = d.getDay();
            const correntMon = d.getMonth();
            const correntDay = d.getDate();
            const dateStr = d.toDateString();

            if (dayOfWeek !== 0 && dayOfWeek !== 6 && !holidaysTTTF.includes(dateStr)) {
                const currentChillDays = chillDays.filter(item => item.month === correntMon && item.day === correntDay);

                if (correntMon !== saveMonth) {
                    workDays.push({
                        month: saveMonth,
                        countDayWork: counterDaysWork,
                        countDayChill: counterDaysChill,
                    });

                    counterDaysChill = 0;
                    counterDaysWork = 0;
                    saveMonth = correntMon;
                }

                if (currentChillDays.length <= 0) {
                    counterDaysWork++;
                }
                else {
                    counterDaysChill++
                }
            }
        }

        workDays.push({
            month: saveMonth,
            countDayWork: counterDaysWork,
            countDayChill: counterDaysChill,
        });

        workDays.shift();

        return workDays;
    };

    async function calculateDifferentTypesSalary(dayWork: { month: number; countDayWork: number; countDayChill: number; }[], salary: number, typeChill: string) {
        var clearForSalary = 0;
        var i;

        for (i = 0; i < dayWork.length; i++) {
            clearForSalary += salary / countWorkDaysTTTF[dayWork[i].month] * dayWork[i].countDayWork;
        }
        

        return clearForSalary;
    }

    // подсчет суммы

    async function calculateSalary(dataForSalary: TypeSalaryValueRequest) {

        const daysWork = await formatedDate(String(dataForSalary.otDateWork), String(dataForSalary.doDateWork), String(dataForSalary.otDateChill), String(dataForSalary.doDateChill));

        const salary = Number(dataForSalary.salary.replace(",", "."));
        var rateFSS = Number(dataForSalary.rateFSS.replace(",", "."));
        var clearForSalary;

        const warning = document.getElementById("WarMROT");

        if (warning != null) {
            if (salary >= mrot)
                warning.style.display = "none";
            else
                warning.style.display = "block"
        }

        if (daysWork[0].countDayWork !== 0)
            clearForSalary = await calculateDifferentTypesSalary(daysWork, salary, dataForSalary.typeChill);
        else
            clearForSalary = 0;

        const poleSummSalaryNoNDFL = document.getElementById("SummSalaryNoNDFL");
        const poleSummaSalary = document.getElementById("SummaSalary");
        const poleSummaNDFL = document.getElementById("SummaNDFL");
        const poleDeductionsIFNS = document.getElementById("DeductionsIFNS");
        const poleDeductionsFCC = document.getElementById("DeductionsFCC");

        const poleDeductionsFCCValue = document.getElementById("DeductionsFCCValue");

        if (rateFSS === 0)
            rateFSS = 0.2;

        if (poleSummSalaryNoNDFL != null) {
            poleSummSalaryNoNDFL.innerText = clearForSalary.toFixed(2) + " ₽";
        }

        if (poleSummaSalary != null) {
            poleSummaSalary.innerText = (clearForSalary - (clearForSalary * 0.13)).toFixed(2) + " ₽";
        }

        if (poleSummaNDFL != null) {
            poleSummaNDFL.innerText = (clearForSalary * 0.13).toFixed(2) + " ₽";
        }

        if (poleDeductionsIFNS != null) {
            if (dataForSalary.statusMCP === "no")
                poleDeductionsIFNS.innerText = (clearForSalary * 0.3).toFixed(2) + " ₽";
            else {
                if (clearForSalary < mrot) {
                    poleDeductionsIFNS.innerText = (clearForSalary * 0.3).toFixed(2) + " ₽";
                }
                else {
                    poleDeductionsIFNS.innerText = ((mrot * 0.3) + ((clearForSalary - mrot) * 0.15)).toFixed(2) + " ₽";
                }
            }
        }

        if (poleDeductionsFCC != null) {
            poleDeductionsFCC.innerText = (clearForSalary * (rateFSS / 100)).toFixed(2) + " ₽";
        }

        if (poleDeductionsFCCValue != null) {
            poleDeductionsFCCValue.innerText = "(" + rateFSS +"%)";
        }

    }

    // Изменение формы

    const handleChangeDataSalaryInput = (e: ChangeEvent<HTMLInputElement>) => {
        const isOnlyNumbers = (str: string): boolean => /^\d*(,\d*)?$/.test(str);

        var input = e.target;
        var correctValue: string;

        if (input.name === "salary" || input.name === "rateFSS") {

            if (!isOnlyNumbers(input.value))
                correctValue = input.value.slice(0, input.value.length - 1);
            else
                correctValue = input.value;
        }
        else {
            correctValue = input.value;
        }

        setDataSalary((prev) => ({
            ...prev,
            [input.name]: correctValue,
        }));
    }

    const handleClickChange = (event: React.MouseEvent<HTMLInputElement>) => {

        const inputValue = event.currentTarget.value;

        if (isCheckedName === "yes") {
            setDataSalary((prev) => ({
                ...prev,
                statusMCP: inputValue,
            }));
        }
        else {
            setDataSalary((prev) => ({
                ...prev,
                statusMCP: inputValue,
            }));
        }
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        var input = event.target;
        var currentName = input.name;
        var currentValue: string; 

        if (input.value === '') {
            if (currentName === "salary") {
                currentValue = "0";
            }
            else if (currentName === "rateFSS") {
                currentValue = "0,2";
            }
            else {
                currentValue = input.value;
            }

            setDataSalary((prev) => ({
                ...prev,
                [currentName]: currentValue,
            }));
        }
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        var input = event.target;

        if (input.value === '0' || input.value === '0,2') {
            setDataSalary((prev) => ({
                ...prev,
                [input.name]: '',
            }));
        }
    };

    // Отмена действий формы (перезагрузка)

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
    };

    // Запуск подсчета суммы

    function startCalculate() {
        calculateSalary(dataSalary);
    }

    function showQue() {
        const element = document.getElementById("UserQue");

        if (element != null) {
            element.style.opacity = "1";
            element.style.zIndex = "20";
        }
    }

    function hideQue() {
        const element = document.getElementById("UserQue");

        if (element != null) {
            element.style.opacity = "0";
            element.style.zIndex = "-20";
        }
    }


    //useEffect(() => {
    //    calculateSalary(dataSalary);
    //}, [dataSalary]);

    return (
        <section className={styles.calculate}>
            <div className="container">
                <section className={styles.questions}>
                    <div className={styles.body}>
                        <div className={styles.left}>

                            <div className={styles.textblock}>
                                <h2 className={styles.titleprice}> Калькулятор зарплаты и страховых взносов </h2>
                                <div className={styles.titletext}>
                                    <div className={styles.titledescription}>
                                        <p> Зарплата сотрудника</p>
                                    </div>
                                </div>
                                <div className={styles.pricetext}>
                                    <p> Сумма зарплаты <br></br> <strong id="countMonth"> до вычета НДФЛ</strong></p>
                                    <span id="SummSalaryNoNDFL"> 0.00 ₽</span>
                                </div>
                                <div className={styles.pricetext}>
                                    <p> Сумма НДФЛ <br></br> <strong id="countMonth"> (13%)</strong></p>
                                    <span id="SummaNDFL"> 0.00 ₽</span>
                                </div>
                                <div className={styles.pricetext}>
                                    <p> Сумма зарплаты <br></br> <strong id="countMonth"> на руки</strong></p>
                                    <span id="SummaSalary">0.00 ₽</span>
                                </div>
                                <div className={styles.titletext}>
                                    <div className={styles.titledescription}>
                                        <p> Отчисления работодателя</p>
                                    </div>
                                </div>
                                <div className={styles.pricetext}>
                                    <p> Страховые взносы <br></br> <strong id="countMonth"> (30%)</strong></p>
                                    <span id="DeductionsIFNS"> 0.00 ₽</span>
                                </div>
                                <div className={styles.pricetext}>
                                    <p> Страховые взносы от <br></br>несчастных случаев <br></br> <strong id="DeductionsFCCValue"> (0,2%)</strong></p>
                                    <span id="DeductionsFCC"> 0.00 ₽</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>


                            <form onSubmit={handleSubmit} className={styles.form}>
                                <div className={styles.fieldblock}>
                                    <div className={styles.fieldhalf}>
                                        <p> Начало периода расчета </p>
                                        <input
                                            name="otDateWork"
                                            type="date"
                                            placeholder="0"
                                            value={dataSalary.otDateWork}
                                            onChange={handleChangeDataSalaryInput}

                                        />
                                    </div>

                                    <div className={styles.fieldhalf}>
                                        <div>
                                            <p> Конец периода расчета</p>
                                        </div>
                                        <input
                                            name="doDateWork"
                                            type="date"
                                            placeholder="0"
                                            value={dataSalary.doDateWork}
                                            onChange={handleChangeDataSalaryInput}
                                        />
                                            
                                    </div>
                                </div>


                                <div className={styles.field + ' ' + styles.marginbottomzero}>
                                    <div>
                                        <p> Включена ли ваша организация в реестр МСП? <strong> *</strong> </p>
                                        <p className={styles.description}>Если вам нужно узнать, включена ли организация в реестр, это можно проверить на сайте ФНС: rmsp.nalog.ru</p>
                                    </div>
                                    <div className={styles.checkboxgroup}>
                                        <label className={styles.castomcheckbox}>
                                            <input
                                                name="reason"
                                                type="radio"
                                                placeholder="0"
                                                value="yes"
                                                onClick={handleClickChange}
                                                
                                            />
                                            <span></span>
                                                Включена
                                        </label>

                                        <label className={styles.castomcheckbox}>
                                            <input
                                                name="reason"
                                                type="radio"
                                                placeholder="0"
                                                value="no"
                                                onClick={handleClickChange}
                                                defaultChecked
                                            />
                                            <span></span>
                                                Не включена
                                        </label>
                                    </div>
                                </div>

                                <div className={styles.field}>
                                    <div className={styles.displayspasebeet}>
                                        <p> Ваш оклад в месяц ₽</p>
                                        <div className={styles.queblock}
                                            onMouseEnter={showQue}
                                            onMouseLeave={hideQue}>
                                            ?
                                            <div id="UserQue" className={styles.quetext}>
                                                <p> В любом случае, если трудоустройство происходит на полную ставку <br></br> <strong>(8 часов в день) </strong> – размер оклада не должен быть меньше МРОТ по РФ <br></br> <strong>(с учетом коэффициента субъекта)</strong>.  </p>    
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        name="salary"
                                        type="text"
                                        placeholder="0 / 0,00"
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        value={dataSalary.salary}
                                        onChange={handleChangeDataSalaryInput} />

                                    <p id="WarMROT" className={styles.warning}> Меньше МРОТ </p>
                                </div>

                                <div className={styles.field}>
                                    <div>
                                        <p> Ваша ставка ФСС %<strong> *</strong> </p>
                                        <p className={styles.description}> Вы можете не вводить данную ставку, тогда в расчете возьмем ставку по умолчанию 0,2% </p>
                                    </div>
                                    <input
                                        name="rateFSS"
                                        type="text"
                                        placeholder="0,2"
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        value={dataSalary.rateFSS}
                                        onChange={handleChangeDataSalaryInput} />
                                </div>

                                <button
                                    className={styles.buttonsalary}
                                    onClick={startCalculate}
                                >
                                    Рассчитать
                                </button>

                               

                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
};

export default CalculatorSalary;

